.container {
  width: 100%;
  min-height: 100vh;
  padding: 3.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.code {
  padding-right: 1.35rem;
  color: var(--color-accent-400);
  font-size: 5rem;
  font-weight: 600;
}

.summary {
  padding-left: 1.35rem;
  color: var(--color-secondary-100);
  text-align: center;
}

@media (max-width: 550px), (max-height: 250px) {
  .container {
    flex-direction: column;
  }

  .code {
    padding-right: 0;
    border-right: none;
  }

  .summary {
    padding-left: 0;
  }
}
