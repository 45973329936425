.container-detail {
	position: relative;
	overflow: hidden;
	z-index: 1;
}

.container-detail::before {
	content: "";
	width: 115vh;
	height: 115vh;
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-25%, -50%);
	border-radius: 0 0 50% 0;
	background-color: var(--color-accent-400);
	background: radial-gradient(var(--color-accent-400), #fe4c5e00);
	filter: blur(35px);
	opacity: 0.25;
	z-index: -1;
}
